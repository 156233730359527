<template>
  <v-app>
    <div v-if="loaded && !closed">
      <center v-if="!formSubmitted && !closed">
        <img
          src="/logo.jpg"
          width="100%"
          style="object-fit: cover; padding-top:5vh"
        />        
        <h3 :style="getFontRegular">
          <br />Vehicle Loan Agreement<br />
        </h3>
      </center>
      <v-main transition="slide-x-transition">
        <v-container v-if="formSubmitted">
          <v-row>
            <v-col cols="12">
              <center>
                <img
                  src="/logo.jpg"
                  width="100%"
                  style="object-fit: cover; padding-top:5vh"
                />                  
                <h3 :style="getFontRegular">
                  <br /><br />Thank you for signature.<br/><br/> You may now close the page.
                </h3>
              </center>
            </v-col>
          </v-row>
        </v-container>
          <v-form v-model="valid" v-if="!formSubmitted && !closed">
           <v-container>
            <v-row>
              <v-col cols="12">
                <p :style="getFontRegular">Between <br></p>
                <ul>
                  <li :style="getFontRegular" style="list-style-type: none;">1.	<b>BMW (UK) Limited</b>  incorporated under the laws of England & Wales with company 
                    number 1378137 having its registered office at Summit One, Summit Avenue, Farnborough, 
                    Hampshire GU14 0FB <b>(“BMW”)</b>.<br><br></li>

                  <li :style="getFontRegular" style="list-style-type: none;" v-if="vehicleLoanForm.loan_type!='PARTNER'">2.	<b>{{vehicleLoanForm.customer_name}}</b> of <b>{{vehicleLoanForm.customer_address}}</b> ["{{getNameOfLoanType(vehicleLoanForm)}}"]. <br><br></li>
                  <li :style="getFontRegular" style="list-style-type: none;" v-if="vehicleLoanForm.loan_type=='PARTNER'">2.	
                    <b>{{vehicleLoanForm.customer_name}}</b> incorporated under the laws of England & Wales with company number <b>{{vehicleLoanForm.company_number}}</b> having its registered office at <b>{{vehicleLoanForm.customer_address}}</b> ("the Partner")
                    <br><br></li>
                </ul>

                <p :style="getFontRegular" v-if="vehicleLoanForm.loan_type=='PARTNER'">Whose correspondence is: <br/><br/><b>{{vehicleLoanForm.customer_name}}</b> <br/><b>{{vehicleLoanForm.customer_address}}</b> <br></p>

                <p :style="getFontRegular" v-if="vehicleLoanForm.loan_type!='PARTNER'">Subject to terms and conditions set out in the Promotional Activities &amp; 
                    [{{getNameOfLoanTypeLong(vehicleLoanForm)}}] Agreement entered into between the parties, BMW will loan to 
                    {{getNameOfLoanType(vehicleLoanForm)}} the following Vehicle (or in the case of unavailability or at BMW’s discretion 
                    from time to time, such other vehicle as may be available) for a maximum period of 90 days: <br></p> 


                <p :style="getFontRegular" v-if="vehicleLoanForm.loan_type=='PARTNER'">Subject to terms and conditions set out in the Promotional Activities Agreement entered into between the parties, 
                  BMW will loan to the Partner the following Vehicle(s) (or in the case of unavailability or at BMW’s discretion from time to time, such other vehicle as may be available) for the Term: </p>

                <p :style="getFontRegular" v-if="vehicleLoanForm.loan_type!='PARTNER'">{{vehicleLoanForm.vehicle_information}} <br></p>
                <p :style="getFontRegular" v-if="vehicleLoanForm.loan_type=='PARTNER'">VEHICLES: {{vehicleLoanForm.vehicle_information}} <br></p>

                <p :style="getFontRegular">FROM:  	{{formatJustDate(vehicleLoanForm.sigantures.start_date)}} <br></p>

                <p :style="getFontRegular">UNTIL: 	{{formatJustDate(vehicleLoanForm.sigantures.end_date)}} <br></p>

                <p :style="getFontRegular">Excess Mileage Charge for each mile in excess of 10,000 miles: {{vehicleLoanForm.vehicle_pence_per_mile}} pence. <br></p>

                <p :style="getFontRegular">In the event that BMW terminates this Vehicle Loan Agreement during its 
                  duration in accordance with Schedule 2 of the Promotional Activities & [{{getNameOfLoanTypeLong(vehicleLoanForm)}} ]  
                  Agreement, BMW will enter into a new Vehicle Loan Agreement with the {{getNameOfLoanType(vehicleLoanForm)}} by sending 
                  a new Loan Agreement, materially in the form of this Schedule 6, to the {{getNameOfLoanType(vehicleLoanForm)}}’s 
                  address as set out in clause 13.1 of the Promotional Activities & [{{getNameOfLoanTypeLong(vehicleLoanForm)}}] 
                  Agreement (by email or by post). Unless {{getNameOfLoanType(vehicleLoanForm)}} notifies BMW within 3 days of receipt of the 
                  new Loan Agreement that he does not accept it, the {{getNameOfLoanType(vehicleLoanForm)}} shall be deemed to have accepted 
                  the new Loan Agreement. In any case if the {{getNameOfLoanType(vehicleLoanForm)}} uses the Vehicle described in the new Loan 
                  Agreement the {{getNameOfLoanType(vehicleLoanForm)}} shall be deemed to have accepted the terms of the new Loan Agreement.</p> 
              </v-col>
              <v-col cols="12" v-if="vehicleLoanForm.sigantures.customer_signature != null">
                <label for="input-12" class="v-label theme--light" style="text-transform: uppercase;"
                  >{{getNameOfLoanTypeLong(vehicleLoanForm)}} SIGNATURE *</label >                
                <p>Signed on : {{formatDate(vehicleLoanForm.sigantures.customer_signature_date)}}</p>
                <p><img :src="baseUrl + 'storage/indemnity/' + vehicleLoanForm.sigantures.customer_signature"/></p>
              </v-col>

              <v-col cols="12" v-if="vehicleLoanForm.sigantures.bmw_signature != null">
                <label for="input-12" class="v-label theme--light" style=""
                  >BMW SIGNATURE *</label >                
                <p>Signed on : {{formatDate(vehicleLoanForm.sigantures.bmw_signature_date)}}</p>
                <p><img :src="baseUrl + 'storage/indemnity/' + vehicleLoanForm.sigantures.bmw_signature"/></p>
              </v-col>


              <v-col cols="12" v-if="vehicleLoanForm.sigantures.purchasing_signature != null">
                <label for="input-12" class="v-label theme--light" style=""
                  >PURCAHSING MANAGER SIGNATURE *</label >                
                <p>Signed on : {{formatDate(vehicleLoanForm.sigantures.purchasing_signature_date)}}</p>
                <p><img :src="baseUrl + 'storage/indemnity/' + vehicleLoanForm.sigantures.purchasing_signature"/></p>
              </v-col>              

              <v-col cols="12" v-if="vehicleLoanForm.sigantures.customer_signature == null">
                <label for="input-12" class="v-label theme--light" style="text-transform: uppercase;"
                  >{{getNameOfLoanTypeLong(vehicleLoanForm)}} SIGNATURE *</label >
                <div
                  id="signaturePadDiv"
                  style="border:1px solid #ccc;width:100%;height:30vh;"
                >
                  <VueSignaturePad
                    width="100%"
                    height="30vh"
                    ref="signaturePad"
                  />
                  <div class="v-text-field__details" v-if="signatureInValid">
                    <div class="v-messages theme--light error--text" role="alert">
                      <div class="v-messages__wrapper">
                        <div class="v-messages__message">
                          Signature is required
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <a @click="undo">UNDO</a>
                  </div>
                </div>
                <br />
              </v-col>
              <v-col cols="12">
                <v-alert :value="alert" prominent type="error">
                  Sorry there seems to have been a problem sending your data
                  please try again later
                </v-alert>
              </v-col>

              <v-col cols="12" v-if="vehicleLoanForm.sigantures.customer_signature == null">
                <center>
                  <v-btn
                    color="primary"
                    @click="submitForm()"
                    style="width:100%"
                    :style="getFontRegular"
                    :disabled="saving"
                    >{{ saving ? "SENDING DATA" : "SUBMIT" }}</v-btn
                  >
                </center>
              </v-col>

              <v-col cols="12">
                <center><small><a href="https://www.bmw.co.uk/en/footer/legal/privacy-policy.html" target="_blank">Privacy Policy</a></small></center><br/><br/><br/>
              </v-col>

            </v-row>
          </v-container>
        </v-form>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
import Moment from "moment";

export default {
  name: "App",
  data: () => ({
    //baseUrl: 'https://bmwdatacapture.test7.mediabasedirect.com/',
    //baseUrl: 'https://leadr.test:44300/',
    baseUrl: 'https://bmwdatacapture.mediabasedirect.com/',
    closed: false,
    loaded:false,
    loanID: '',
    logo:'e68b8f0e-a93a-4a85-8870-f4e393750506.jpg',
    event_brand: 'BMW',
    vehicleLoanForm: [],
    signatueOption: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgb(255,255,255)"
    },
    signatureInValid: false,
    valid: false,
    validForm: true,        
    formSubmitted: false,
    saving: false,
    alert: false,    
  }),

  mounted() {



    if (this.$root.$router.currentRoute.params['id']) {
      this.loanID = this.$root.$router.currentRoute.params['id'];
    } else {
      this.activeForm = false;
      this.closed = true;
    }

    this.getData();



  },
  computed: {
    logoURL() {
      return this.baseUrl + this.logo;
    },    
    getFontRegular() {
      return 'font-family: "' + this.event_brand + ' Type Next Regular" !important;'
    },
    getFontBold() {
      return 'font-family: "' + this.event_brand + ' Type Next Bold" !important;'
    }
  },  
  methods: {

    formatDate(date) {
      var retStr = '';

      var formatDate = Moment(date);
      retStr = formatDate.format("dddd, MMMM Do YYYY, h:mm:ss a");

      return retStr;

    },

    getNameOfLoanType(vehicleLoanForm) {
      var retStr = vehicleLoanForm.loan_type;
      if (vehicleLoanForm.loan_type == 'OTHER') {
        retStr=vehicleLoanForm.loan_type_other;
      }
      return retStr;
    },

    getNameOfLoanTypeLong(vehicleLoanForm) {
      var retStr = ''       
      if (vehicleLoanForm.loan_type == 'OTHER') {
        retStr=vehicleLoanForm.loan_type_other;
      }
      if (vehicleLoanForm.loan_type == 'FOB') {
        retStr='Friend of the Brand';
      }
      if (vehicleLoanForm.loan_type == 'PARTNER') {
        retStr='Partner';
      }
      return retStr;
    },

    formatJustDate(date) {
      var retStr = '';

      var formatDate = Moment(date);
      retStr = formatDate.format("dddd, MMMM Do YYYY");

      return retStr;

    },

    getData() {
      axios.get(this.baseUrl + '/loancapture/' + this.loanID)
          .then(
              rsp => {
                  console.log(rsp);

                  this.vehicleLoanForm = rsp.data.vehicleLoanForm;

                  this.loaded = true;                      


              }
          )
    },

    submitForm() {
      this.validForm = true;
      this.signatureInValid = false;

      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty && this.validForm) {
        this.validForm = false;
        const el = this.$el.querySelector("#signaturePadLabel");
        if (el) {
          el.scrollIntoView();
        }
        this.signatureInValid = true;
      }

      var signaturedata = data;


      if (this.validForm) {
        // form is valid lets send the form

        this.saving = true;
        this.alert = false;

        var url =
          this.baseUrl + "capture/loandata";
         
        let fd = new FormData();
        fd.append("signature", signaturedata);
        fd.append("loan_id", this.loanID);

        axios
          .post(url, fd)
          .then(() => {
            this.formSubmitted = true;
            this.saving = false;
          })
          .catch(error => {
            console.log({ ...error });
            this.alert = true;
            this.saving = false;
          });
      }
          
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    }

  }
};
</script>

<style>
@font-face {
  font-family: "BMW Type Next Bold";
  src: url("./assets/BMWTypeNextLatinTT-Bold.woff") format("woff");
}
@font-face {
  font-family: "BMW Type Next Regular";
  src: url("./assets/BMWTypeNextLatinTT-Regular.woff") format("woff");
}
@font-face {
  font-family: "BMW Type Next Regular";
  src: url("./assets/BMWTypeNextLatinTT-Light.woff") format("woff");
}

@font-face {
  font-family: "MINI Type Next Bold";
  src: url("./assets/mini_serif-bold-web.woff") format("woff");
}

@font-face {
  font-family: "MINI Type Next Regular";
  src: url("./assets/mini_serif-regular-web.woff") format("woff");
}

@font-face {
  font-family: "BMW Motorrad Type Next Bold";
  src: url("./assets/BMWMotorradW05-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "BMW Motorrad Type Next Regular";
  src: url("./assets/BMWMotorradW05-Regular.woff2") format("woff2");
}



.subTitle {
  font-family: "MOTORAD Type Next Bold" !important;
  border-bottom: 1px solid #ccc;
  font-size: 1.3em;
  display: block;
  width: 100%;
}
.v-application--wrap {
  max-width: 800px !important;
  margin-left: auto;
  margin-right: auto;
}
.v-messages__message {
  color: red;
  font-size: 1.2em;
  font-weight: bold;
}
</style>
